#root > div {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content {
   flex: 1;
}

/* Inter */

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/400.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/500.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/600.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/700.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/800.ttf');
  font-weight: 800;
  font-style: normal;
}

/* End Inter */

/* Futura */

@font-face {
  font-family: 'Futura';
  src: url('/fonts/Futura/500.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/Futura/600.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/Futura/700.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/Futura/800.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/Futura/900.ttf');
  font-weight: 900;
  font-style: normal;
}

/* End Futura */
